.videoModalPlayer {
    --resize-btn-size: 40px;

    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    min-height: 250px;
    z-index: 1000;
    color: white;
    border-radius: 10px;
    background-color: royalblue;
    padding-bottom: var(--resize-btn-size);
}

.videoModalIcon {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.videoModalIcon img {
    --size: 50px;
    width: var(--size);
    height: var(--size);
}

.videoModalPlayerHeader {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.videoModalResize {
    cursor: se-resize;
    background-color: rgba(0,0,0,0.1);
    width: var(--resize-btn-size);
    height: var(--resize-btn-size);
    position: absolute;
    right: 0;
    bottom: 0;
}