.loginContainer {
    --pad: 15px;
    --bRad: 10px;

    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
    padding-top: var(--pad);
    padding-bottom: var(--pad);
    margin-top: var(--pad);
    margin-bottom: var(--pad);
    
    border-radius: var(--bRad);
    display: flex;
    flex-direction: column;
}
.loginContainer input {
    height: 30px;
    border-radius: var(--bRad);
}

.loginContainer input[type='text'], .loginContainer input[type='password']{
    margin-bottom: var(--pad);
}

.loginContainer input[type='button'] {
    background-color: royalblue;
    border-radius: var(--bRad);
    height: 50px;
    color: white;
    font-weight: bold;
}

.loginInputContainer {
    display: flex;
    flex-direction: row;
}

.loginClearBtn {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 20px !important;
}