

.customButtonContainer {

    --size: 150px;

    position: relative;
    display: inline-flex;
    width: var(--size);
    height: var(--size);
    border-radius: 10px;
    box-shadow: 0 0 10px 0;
    margin: 15px;
    align-items: center;
    justify-content: center;
    color: royalblue;
    font-size: 25px;
}