.menuComponent {
    --bRad: 10px;

    user-select: none;
    width: 100%;
    height: 100%;
    border-radius: var(--bRad);
    background-color: white;
    padding: 20px;
}

.menuComponent h2 {
    color: white;
}

.menuButton {
    position: relative;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    background-color: silver;
    color: royalblue;
    color: white;
    border-radius: var(--bRad);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 0 #666;
}

.menuButton:hover {
    background-color: #ddd;
}

.menuButton:active {
    background-color: #ccc;
}

h2 {
    width: 100%;
    text-align: center;
    margin-top: 0;
}