.pageComponent {

    --navBar-height: 100px;
    
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}

.pageChildren {
    position: absolute;
    top: var(--navBar-height);
    width: 100%;
    height: calc(100% - var(--navBar-height));
}

.pageLoginWidget {
    position: absolute;
    z-index: 1;
    top: 50px;
    width: 300px;
    height: 200px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
}

.pageMenu {
    position: absolute;
    display: flex;
    right: 10px;
    top: calc(var(--navBar-height) + 10px);
    width: 400px;
}






.pageNavBarBtn {
    --size: calc(var(--login-btn-size));

    position: absolute;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    overflow: hidden;
    
    display: flex;
    align-items: center;
    justify-content: center;

}

.pageHomeBtn {
    left: calc(var(--login-btn-pad)/2);
    top: calc(var(--login-btn-pad)/2);
}

.pageLoginBtn {
    right: calc(var(--login-btn-pad)/2);
    top: calc(var(--login-btn-pad)/2);

}

.pageBtnImage {
    width: auto;
    height: auto;
    height: calc(var(--size)*2);
}

.pageTopBar {
    position: absolute;
    top: 0;
    left: 0;
    height: var(--navBar-height);
    width: 100%;
    display: flex;
    align-items: center;
}

.pageHomeContainer {

    --login-btn-pad: 30px;
    --login-btn-size: calc(var(--navBar-height) - var(--login-btn-pad));
    
    position: absolute;
    left: 0;
    top: 0;
    height: var(--navBar-height);
    width: calc(var(--navBar-height)*2);

    font-weight: bold;
    font-size: 12px;

}

.pageLoginContainer {

    --login-btn-pad: 30px;
    --login-btn-size: calc(var(--navBar-height) - var(--login-btn-pad));
    
    position: absolute;
    right: 0;
    top: 0;
    height: var(--navBar-height);
    width: calc(var(--navBar-height));

    font-weight: bold;
    font-size: 12px;

}

.navBarVideoBtn {
    --size: 50px;
    
    position: absolute;
    right: var(--navBar-height);
    height: var(--size);
    width: var(--size);
    height: var(--navBar-height);
    width: var(--navBar-height);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.navBarVideoBtn:hover {
    background-color: rgba(0,0,0,0.1);
}

.navBarVideoBtn:active {
    background-color: rgba(0,0,0,0.2);
}